import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closePopup } from '../actions/popup';
import { getProductSkuDropdown } from '../selectors/dropdowns';

import { BASE_ZINDEX } from '../popup-factory';
import { formatMoney, isNumeric } from '../utils';

import { reduceSingleBreakdown, changeItemBreakdown } from './ItemBreakdown';

import { createUpdate } from '../actions';
import { UPDATE_ITEM_BREAKDOWN_FAILURE, UPDATE_ITEM_BREAKDOWN_REQUEST, UPDATE_ITEM_BREAKDOWN_SUCCESS } from '../actions/item';
const updateItemBreakdown = createUpdate('breakdown', {
  request: UPDATE_ITEM_BREAKDOWN_REQUEST,
  success: UPDATE_ITEM_BREAKDOWN_SUCCESS,
  failure: UPDATE_ITEM_BREAKDOWN_FAILURE,
  failure_message: 'Unable to update item breakdown'
});

const getOptionDescriptionFromSku = (skus, sku) => {
  const sku_options = skus.reduce(
    (t, s) => ({
      ...t,
      ...s.options.reduce(
        (o, option) => ({
          ...o,
          [option.option_axis]: Array.from(new Set((t[option.option_axis] || []).concat(option.option_name)))
        }),
        {}
      )
    }),
    {}
  );
  const axes = Object.keys(sku_options).filter(a => 'dimension' !== a || (sku_options[a].length > 1 && !sku_options['size'])).concat('sku');
  const options = (sku.options.length > 0 ? sku.options : [{ option_axis: 'sku', option_name: sku.sku }]).filter(o => axes.includes(o.option_axis));
  return options.sort(
    (a, b) => a.option_axis < b.option_axis ? -1 : (a.option_axis > b.option_axis ? 1 : 0)
  ).map(
    o => o.option_name
  ).join('/');
};

const Breakdown = ({ item, skus, initialBreakdown, locked }) => {
  const formattedInitialBreakdown = { ...initialBreakdown, quantity: parseFloat(initialBreakdown.quantity).toFixed(0) };
  const [breakdown, setBreakdown] = useState(formattedInitialBreakdown);
  const item_sku = !!item.item_sku ? item.item_sku : (!!item.product ? item.product.product_supplier_code : null);
  const size_name = item.sizes.filter(s => s.size_id === breakdown.size_id)[0]?.size_name ?? 'TBD';
  const color_name = item.colors.filter(c => c.color_id === breakdown.color_id)[0]?.color_name ?? 'TBD';
  const sku = (skus || []).filter(sku => sku.product_sku_id === breakdown.product_sku_id)[0];
  const optionDescription = !!breakdown.product_sku_id ? (!!sku ? getOptionDescriptionFromSku(skus, sku) : '') : `${size_name}/${color_name}`;
  const client_price = parseFloat(breakdown.unit_price) + parseFloat(item.hidden_costs);
  const total_subtotal = client_price * parseFloat(breakdown.quantity);

  const isValidQuantity = quantity => isNumeric(quantity) && parseFloat(quantity) === parseInt(quantity, 10);

  const onChangeQuantity = e => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    if (isValidQuantity(value)) {
      setBreakdown(reduceSingleBreakdown(initialBreakdown, changeItemBreakdown(initialBreakdown, 'quantity', value)));
    } else {
      setBreakdown({ ...breakdown, quantity: value });
    }
  };
  const onUpdateQuantity = e => {
    if (isValidQuantity(breakdown.quantity)) {
      const value = parseFloat(breakdown.quantity).toFixed(0);
      if (value === formattedInitialBreakdown.quantity) {
        setBreakdown(formattedInitialBreakdown);
      } else {
        dispatch(updateItemBreakdown(breakdown.breakdown_id, 'quantity', initialBreakdown.quantity, value)).then(
          action => {
            if (UPDATE_ITEM_BREAKDOWN_FAILURE === action.type) {
              setBreakdown(formattedInitialBreakdown);
            }
          }
	);
      }
    } else {
      setBreakdown(formattedInitialBreakdown);
    }
  };

  useEffect(() => setBreakdown(formattedInitialBreakdown), [initialBreakdown]);
  const dispatch = useDispatch();

  return (
    <tr>
      <td><input type="text" readOnly={true} value={optionDescription} /></td>
      <td><input type="text" readOnly={true} value={!!breakdown.warehouse_code ? breakdown.warehouse_code : (breakdown.sku ? breakdown.sku : item_sku)} /></td>
      <td>
        <input type="text" disabled={locked} className="text-right" value={breakdown.quantity} onChange={onChangeQuantity} onBlur={onUpdateQuantity} />
      </td>
      <td style={{ position: 'relative' }}>
        <span className="dollar">$</span>
        <input type="text" readOnly={true} className="text-right" value={breakdown.unit_cost} />
      </td>
      <td style={{ position: 'relative' }}>
        <input type="text" readOnly={true} className="text-right indent-percent" value={breakdown.breakdown_margin} />
        <span className="percent">%</span>
      </td>
      <td style={{ position: 'relative' }}>
        <span className="dollar">$</span>
        <input type="text" readOnly={true} className="text-right" value={breakdown.unit_price} />
      </td>
      <td>${formatMoney(client_price)}</td>
      <td>${formatMoney(total_subtotal)}</td>
    </tr>
  );
};

const EditInventoryBreakdownQuantityPopup = ({ item, locked, index }) => {
  const skus = useSelector((state) => getProductSkuDropdown(state, { product_id: item.parent_id }));
  const dispatch = useDispatch();
  const onClosePopup = () => dispatch(closePopup());

  return (
    <div className="reveal large" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial' }}>
      <div className="row">
        <h3>Adjust {item.item_name} Quantities</h3>
        <a className="button" style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={onClosePopup}>Close</a>
      </div>
      <div className="row popup-content">
        <table>
          <thead>
            <tr>
	      <th>Size/Color</th>
	      <th>SKU</th>
	      <th>Qty</th>
	      <th>Net Cost</th>
	      <th>Margin</th>
	      <th>Retail</th>
	      <th>Client Price</th>
	      <th>Total</th>
            </tr>
          </thead>
          <tbody>
	    {item.breakdowns.filter(ib => !!ib.inventory_item_id).map(
              ib => <Breakdown key={ib.breakdown_id} item={item} skus={skus} initialBreakdown={ib} locked={locked} />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EditInventoryBreakdownQuantityPopup;
